const styles = {
  modal: {
    // TODO can we use a generic style for this?
    width: [296, 296, 526, 526, 850, 850],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'center',
      'center'
    ],
    fontSize: 4
  },
  scrollable: {
    label: 'Feedback-scrollable',
    flexDirection: 'column',
    maxHeight: '70vh',
    overflowX: 'hidden',
    maxWidth: '100%',
    overflowY: 'auto',
    alignItems: ['unset', 'unset', 'unset', 'unset', 'center', 'center']
  },
  recommendationContainer: {
    width: '100%',
    mt: 3
  },
  numbersContainer: {
    position: 'relative',
    width: ['100%', '100%', '100%', 333],
    height: ['100%', '100%', '100%', 42],
    margin: [0, 0, 0, 0, '0 auto', '0 auto'],
    justifyContent: [
      'center',
      'center',
      'flex-start',
      'flex-start',
      'center',
      'center'
    ],
    color: 'secondary',
    display: 'flex',
    flexWrap: 'wrap'
  },
  numbersSecondLineContainer: {
    ml: ['1%', '1%', 0, 0, 0, 0]
  },
  numberLabels: {
    width: 333,
    justifyContent: 'space-between',
    color: 'secondary',
    margin: [0, 0, 0, 0, '0 auto', '0 auto']
  },
  notLikely: {
    fontSize: '12px',
    display: ['none', 'none', 'block']
  },
  extremelyLikely: {
    fontSize: '12px',
    display: ['none', 'none', 'block']
  },
  number: {
    py: 3,
    height: 24,
    width: 24,
    fontSize: 1,
    cursor: 'pointer',
    borderRadius: 'button',
    border: '1px solid transparent',
    borderColor: 'secondary',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.5s',
    mr: [0, 0, '6px', '6px', 0, 0],
    mb: ['10px', '10px', '10px', 0]
  },
  starsContainer: {
    color: 'primary'
  },
  textArea: {
    width: ['100%', '100%', '100%', '100%', 418, 418],
    minHeight: [47, 47, 90, 90, 90, 90],
    mt: [2, 2, 2, 2, 3, 3],
    mb: [4, 4, 4, 4, 10, 10],
    fontSize: 2,
    fontFamily: 'body',
    border: '1px solid transparent',
    borderColor: 'grey',
    borderRadius: 'button',
    mx: 'auto'
  },
  title: {
    mt: [0, 0, 0, 0, 1, 1],
    mb: [0, 0, 0, 0, 4, 4],
    mx: [0, 0, 0, 0, '12px', '12px'],
    width: '100%',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: '18px',
    fontWeight: 600
  },
  checkboxLabel: {
    label: 'Feedback-checkbox-label',
    fontSize: 0,
    mx: 1,
    alignItems: 'center'
  },
  checkboxOption: {
    label: 'Feedback-checkbox-option',
    alignItems: 'center',
    py: 2,
    mr: 2,
    position: 'relative'
  },
  multiSelect: {
    label: 'Feedback-multi-select',
    flexWrap: 'wrap',
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'center',
      'center'
    ]
  },
  singleSelect: {
    label: 'Feedback-single-select',
    my: 4,
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row']
  },
  emailPrompt: {
    label: 'Feedback-email-prompt',
    mb: [0, 0, 0, 0, 4, 4],
    fontSize: '18px'
  },
  emailWrapper: {
    label: 'Feedback-email-wrapper',
    fontSize: 2,
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    alignItems: [
      'center',
      'center',
      'center',
      'center',
      'flex-start',
      'flex-start'
    ]
  },
  emailInput: {
    label: 'Feedback-email-input',
    fontFamily: 'body'
  },
  emailContainer: {
    label: 'Feedback-email-container',
    display: 'inline-flex',
    flexDirection: 'column',
    maxWidth: 380,
    mb: 10,
    '& input': {
      maxWidth: 379,
      borderRadius: 'button',
      border: '1px solid transparent',
      borderColor: 'grey'
    },
    '&.error': {
      color: 'error'
    },
    '&.error input': {
      border: '1px solid transparent',
      borderColor: 'error'
    }
  },
  label: {
    width: 'fit-content',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    mt: 3,
    mr: 2
  },
  select: {
    label: 'Feedback-select',
    my: 4
  }
}

export default styles
