import { Input, Radio } from '@rebass/forms'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { isEmailValid } from '../../../utils/utils'
import Overlay from '../../generic/Overlay'
import EndSessionActions from '../EndSessionActions'
import styles from './styles'
import { useTranslation } from 'react-i18next'

interface EscalationFormProps {
  restart: () => void
}
const EscalationForm: React.FC<EscalationFormProps> = ({ restart }) => {
  const {
    escalationFormOpen,
    escalationFormFromServer,
    feedbackGiven
  } = useUneeqState()
  const { t } = useTranslation()

  const [shouldContact, setShouldContact] = useState<boolean | null>(
    escalationFormFromServer === true ? true : null
  )
  useEffect(() => {
    if (escalationFormFromServer) {
      setShouldContact(true)
    }
  }, [escalationFormFromServer])

  const { dispatch, sendData } = useContext(UneeqContext)

  if (!escalationFormOpen) return null

  const leaveChat = () => {
    if (!feedbackGiven) {
      dispatch({ type: 'openFeedback', payload: true })
    } else {
      restart()
    }
  }

  return (
    <Overlay>
      <Box variant="modal" sx={styles.modal}>
        <Text sx={styles.heading}>
          {escalationFormFromServer
            ? t('endSession.EscalationForm.headingRefer')
            : feedbackGiven
            ? t('endSession.EscalationForm.headingThanksFeedback')
            : t('endSession.EscalationForm.headingBeforeYouLeave')}
        </Text>
        {!escalationFormFromServer && (
          <Text sx={styles.subtitle}>
            {t('endSession.EscalationForm.subtitle')}
          </Text>
        )}
        {/* {!escalationFormFromServer && (
          <Flex sx={{ ...styles.optionsContainer, mb: shouldContact ? 6 : 12 }}>
            <Label sx={styles.radioLabel}>
              <Radio
                id="shouldContact"
                name="shouldContact"
                value="yes"
                onChange={() => setShouldContact(true)}
                sx={styles.radio}
              />
              {t('endSession.EscalationForm.yes')}
            </Label>
            <Label sx={styles.radioLabel}>
              <Radio
                id="shouldContact"
                name="shouldContact"
                value="no"
                onChange={() => setShouldContact(false)}
                sx={styles.radio}
              />
              {t('endSession.EscalationForm.no')}
            </Label>
          </Flex>
        )} */}

        <EndSessionActions
          onConfirm={trackHandler(leaveChat, 'escalation-form-leave-chat-btn')}
          onDismiss={trackHandler(() => {}, 'escalation-form-back-to-chat-btn')}
        />
      </Box>
    </Overlay>
  )
}

export default EscalationForm
