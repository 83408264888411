const styles = {
  radioLabel: {
    alignItems: 'center'
  },
  radio: {
    width: 11,
    height: 11,
    color: 'secondary',
    marginRight: '5px'
  },
  modal: {
    minHeight: 344,
    px: ['5%', '5%', '5%', 127],
    fontSize: 1
  },
  subtitle: {
    textAlign: 'center',
    mb: 10
  },
  heading: {
    fontSize: 4,
    mb: 10
  },
  optionsContainer: {
    justifyContent: 'center',
    mt: 6,
    '& label': {
      mr: 4,
      width: 'auto'
    }
  }
}

export default styles
