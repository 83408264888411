import React from 'react'
import { Flex, Box } from 'rebass'
import { Label, Radio } from '@rebass/forms'

import styles from './styles'

const SingleSelect = ({ name, options, selected, setter }: any) => {
  if (!options) return null

  // BOX required: if removed it breaks the responsive on Safari
  return (
    <Box>
      <Flex sx={styles.singleSelect}>
        {options &&
          options.map((option: any) => (
            <Flex
              key={option.id}
              sx={styles.checkboxOption}
              alignItems="center"
            >
              <Label htmlFor={option.id} sx={styles.checkboxLabel}>
                <Radio
                  id={option.id}
                  name={name}
                  value={option.id}
                  onChange={() => setter(option.id)}
                  checked={selected === option.id}
                />
                {option.label}
              </Label>
            </Flex>
          ))}
      </Flex>
    </Box>
  )
}

export default SingleSelect
