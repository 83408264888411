import downloadPdf from './downloadPdf'

let environment = {}
if (process.env.REACT_APP_CONFIG === 'vercel') {
  console.info('config: vercel')
  environment = {
    tokenUrl: '/api/token'
  }
} else if (process.env.REACT_APP_CONFIG === 'UAT') {
  console.info('config: UAT')
  environment = {
    conversationId: '6add1a9a-5a1c-49ef-8bf8-0938d550e6e9', // UAT
    tokenUrl: 'https://asb-josie-uat-bot.azurewebsites.net/api/uneeq/token' // TEST
  }
} else if (process.env.REACT_APP_CONFIG === 'PRODUCTION') {
  console.info('config: PRODUCTION')
  environment = {
    conversationId: 'f63f8109-1120-4471-9e31-92ee8c052639', // PRODUCTION
    tokenUrl: 'https://asb-josie-prod-bot.azurewebsites.net/api/uneeq/token' // TEST
  }
} else {
  console.info('config: default')
}

const config = {
  debug: true,

  playWelcome: !process.env.REACT_APP_NO_WELCOME,
  url: 'https://api.us.uneeq.io',
  conversationId: '6e387c39-6065-4aa5-85a0-344cfc0cb39d', // TEST
  tokenUrl: 'https://wsdev.mayamd.ai/getUneeqToken', // TEST

  sendLocalVideo: false,
  sendLocalAudio: false,
  customData: {},

  // When holding down spacebar, durations shorter that this will trigger
  // a message like "Keep holding spacebar while you talk"
  tapThreshold: 700, // ms

  // How long can the user be inactive before timeout
  timeout: 480 * 1000, // ms
  // How close to the end of the timeout should we show the warning
  timeoutWarning: 180 * 1000, // ms
  // e.g. timeout=90sec, timeoutWarning=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends

  avatarAspect: 16 / 9, // video aspect ratio
  avatarPosition: 0.32, // Where is the avatar in the video frame

  informationInTranscript: false,

  // How many empty transcripts before an error is shown
  emptyTranscriptThreshold: 3,
  avatarName: 'Maya',

  // Recaptcha
  recaptchaSiteKey: '6Le-E_0UAAAAAN3G4UAMOTfk5Edg2dWilsPb2gmz',

  downloadPdf,

  ...environment
}

export default config
