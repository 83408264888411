import { Textarea, Input } from '@rebass/forms'
import React, { useContext, useState } from 'react'
import { Box, Card, Flex, Text } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import Overlay from '../../generic/Overlay'
import EndSessionActions from '../EndSessionActions'
import styles from './styles'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import { isEmailValid } from '../../../utils/utils'
import { Trans, useTranslation } from 'react-i18next'

interface FeedbackProps {
  restart: () => void
  isOpen: boolean
}

const NumbersList = ({ values, setter, selectedValue, slug }: any) => {
  return values.map((num: any) => (
    <Flex
      key={num}
      data-testid={`${slug}-${num}`}
      onClick={() => setter(num === 11 ? 5 : num)}
      sx={{
        ...styles.number,
        color:
          selectedValue !== undefined && num <= selectedValue
            ? 'white'
            : 'secondary',
        backgroundColor:
          selectedValue !== undefined && num <= selectedValue
            ? 'secondary'
            : 'white'
      }}
    >
      {num}
    </Flex>
  ))
}

const Feedback: React.FC<FeedbackProps> = ({ restart, isOpen }) => {
  const [email, setEmail] = useState('')
  const [feedbackScore, setFeedbackScore] = useState<number | undefined>()
  const [easyInfoScore, setEasyInfoScore] = useState<number | undefined>()
  const [considerUsing, setConsiderUsing] = useState<number | undefined>()
  const [comfortableUsingFor, setComfortableUsingFor] = useState({})
  const [openToUsingWhere, setOpenToUsingWhere] = useState({})
  const [recommendationScore, setRecommendationScore] = useState<
    number | undefined
  >()
  const [whatDoYouLike, setWhatDoYouLike] = useState<string | undefined>()
  const [whatWouldYouImprove, setWhatWouldYouImprove] = useState<
    string | undefined
  >()
  const { dispatch, config, sendData } = useContext(UneeqContext)
  const { t } = useTranslation()

  if (!isOpen) return null

  const validEmail = isEmailValid(email)

  const errorInEmail = email && !validEmail

  const submit = () => {
    sendData({ email })
    console.info('Email sent', { email })
    dispatch({ type: 'giveContactDetails' })
  }

  const giveFeedback = () => {
    if (
      feedbackScore ||
      whatDoYouLike ||
      whatWouldYouImprove ||
      easyInfoScore ||
      considerUsing ||
      comfortableUsingFor ||
      openToUsingWhere
    ) {
      sendData({
        feedbackScore,
        whatDoYouLike,
        easyInfoScore,
        whatWouldYouImprove,
        recommendationScore,
        considerUsing,
        comfortableUsingFor,
        openToUsingWhere
      })
      console.info('form sent', {
        rating: feedbackScore,
        whatDoYouLike,
        whatWouldYouImprove,
        easyInfo: easyInfoScore,
        recommendationScore
      })
    }
    dispatch({ type: 'giveFeedback' })
  }
  const leaveChat = () => {
    if (validEmail) {
      giveFeedback()
      submit()
      if (config.showEscalationForm) {
        dispatch({ type: 'openEscalationForm', payload: true })
      } else {
        restart()
      }
    }
  }

  return (
    <Overlay>
      <Flex variant="modal" sx={styles.modal}>
        <Flex sx={styles.scrollable}>
          <Text sx={styles.title} variant="heading">
            {t('endSession.Feedback.heading')}
          </Text>

          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.howLikelySubtitle`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              How likely are you to recommend our digital representative to a
              friend or colleague?
            </Trans>
          </Text>
          <Box sx={styles.recommendationContainer}>
            <Flex sx={styles.numberLabels}>
              <Text sx={styles.notLikely}>
                {t('endSession.Feedback.notVeryLikely')}
              </Text>
              <Text sx={styles.extremelyLikely}>
                {t('endSession.Feedback.extremelyLikely')}
              </Text>
            </Flex>
            <Flex sx={styles.numbersContainer}>
              <Flex>
                <NumbersList
                  values={[0, 1, 2, 3, 4, 5]}
                  setter={setRecommendationScore}
                  selectedValue={recommendationScore}
                  slug={'recomm'}
                />
              </Flex>
              <Flex sx={styles.numbersSecondLineContainer}>
                <NumbersList
                  values={[6, 7, 8, 9, 10]}
                  setter={setRecommendationScore}
                  selectedValue={recommendationScore}
                  slug={'recomm'}
                />
              </Flex>
            </Flex>
          </Box>

          <Text sx={styles.subtitle}>
            {t('endSession.Feedback.howEasySubtitle')}
          </Text>
          <Box sx={styles.recommendationContainer}>
            <Flex sx={styles.numberLabels}>
              <Text sx={styles.notLikely}>
                {t('endSession.Feedback.notVeryEasy')}
              </Text>
              <Text sx={styles.extremelyLikely}>
                {t('endSession.Feedback.extremelyEasy')}
              </Text>
            </Flex>
            <Flex sx={styles.numbersContainer}>
              <Flex>
                <NumbersList
                  values={[0, 1, 2, 3, 4, 5]}
                  setter={setRecommendationScore}
                  selectedValue={recommendationScore}
                  slug={'easy'}
                />
              </Flex>
              <Flex sx={styles.numbersSecondLineContainer}>
                <NumbersList
                  values={[6, 7, 8, 9, 10]}
                  setter={setFeedbackScore}
                  selectedValue={feedbackScore}
                  slug={'easy'}
                />
              </Flex>
            </Flex>
          </Box>
          <Text sx={styles.subtitle}>
            {t('endSession.Feedback.howEasyInfoSubtitle')}
          </Text>
          <Box sx={styles.recommendationContainer}>
            <Flex sx={styles.numberLabels}>
              <Text sx={styles.notLikely}>
                {t('endSession.Feedback.notVeryEasy')}
              </Text>
              <Text sx={styles.extremelyLikely}>
                {t('endSession.Feedback.extremelyEasy')}
              </Text>
            </Flex>
            <Flex sx={styles.numbersContainer}>
              <Flex>
                <NumbersList
                  values={[0, 1, 2, 3, 4, 5]}
                  setter={setRecommendationScore}
                  selectedValue={recommendationScore}
                  slug={'easy-info'}
                />
              </Flex>
              <Flex sx={styles.numbersSecondLineContainer}>
                <NumbersList
                  values={[6, 7, 8, 9, 10]}
                  setter={setEasyInfoScore}
                  selectedValue={easyInfoScore}
                  slug={'easy-info'}
                />
              </Flex>
            </Flex>
          </Box>
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.howLikelyFindInformation`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              How likely would you be to consider using Maya to find information
              on your banking queries in the future?
            </Trans>
          </Text>
          <SingleSelect
            name="considerUsing"
            selected={considerUsing}
            setter={setConsiderUsing}
            options={[
              { id: 1, label: 'Very unlikely' },
              { id: 2, label: 'Unlikely' },
              { id: 3, label: 'Neutral' },
              { id: 4, label: 'Likely' },
              { id: 5, label: 'Very likely' }
            ]}
          />
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.openToUsing`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              Where would you be open to using Maya? Please select all that
              apply.
            </Trans>
          </Text>
          <Text sx={{ fontSize: 1, fontStyle: 'italic' }}>
            Please select all that apply.
          </Text>
          <MultiSelect
            selected={openToUsingWhere}
            setter={setOpenToUsingWhere}
            options={[
              { id: 6, label: 'In-branch kiosk' },
              { id: 7, label: 'On mobile/tablet' },
              { id: 8, label: 'On a desktop computer or laptop' },
              { id: 9, label: 'Other (please specify)', input: true }
            ]}
          />
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.comfortableUsing`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              Which of the following would you be comfortable using Maya for?
              Please select all that
            </Trans>
          </Text>
          <Text sx={{ fontSize: 1, fontStyle: 'italic' }}>
            Please select all that apply.
          </Text>
          <MultiSelect
            selected={comfortableUsingFor}
            setter={setComfortableUsingFor}
            options={[
              { id: 10, label: 'Transfer money to a friend' },
              { id: 11, label: 'Book an appointment with the bank' },
              { id: 12, label: 'Make an international money transfer' },
              { id: 13, label: 'Help you fill in a form' },
              { id: 14, label: 'Apply for a new banking product' },
              { id: 15, label: 'Get investment/financial advice' },
              { id: 16, label: 'Other (please specify)', input: true },
              { id: 17, label: 'None of the above', exclusive: true }
            ]}
          />
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.whatDoYouLike`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              What, if anything, do you like about Maya?
            </Trans>
          </Text>
          <Textarea
            sx={styles.textArea}
            id="comment"
            data-testid="comment-textarea"
            name="comment"
            value={whatDoYouLike}
            onChange={e => setWhatDoYouLike(e.target.value)}
          />
          <Text sx={styles.subtitle}>
            <Trans
              i18nKey={`endSession.Feedback.whatWouldYouImprove`}
              values={{ avatarName: config.avatarName }}
              t={t}
            >
              What, if anything, could ASB do to improve Maya?
            </Trans>
          </Text>
          <Textarea
            sx={styles.textArea}
            id="comment"
            data-testid="comment-textarea"
            name="comment"
            value={whatWouldYouImprove}
            onChange={e => setWhatWouldYouImprove(e.target.value)}
          />
          <Text sx={styles.emailPrompt}>
            {/* {t('endSession.EscalationForm.emailPromptFromServer')} */}
            {t('endSession.EscalationForm.emailPromptNotFromServer')}{' '}
          </Text>
          {/* BOX: required for Safari responsive issue */}
          <Box>
            <Flex sx={styles.emailWrapper}>
              <Box as="label" htmlFor="email" sx={styles.label}>
                {t('endSession.EscalationForm.email')}
              </Box>
              <Box
                sx={styles.emailContainer}
                className={errorInEmail ? 'error' : ''}
              >
                <Input
                  type="email"
                  data-testid="escalation-email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  sx={styles.emailInput}
                />
                {errorInEmail && (
                  <Text>{t('endSession.EscalationForm.invalidEmail')}</Text>
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>

        <EndSessionActions
          onConfirm={trackHandler(leaveChat, 'rate-session-leave-chat-btn')}
          onDismiss={trackHandler(
            giveFeedback,
            'rate-session-back-to-chat-btn'
          )}
          disabled={!validEmail}
          confirmText={t('endSession.EndSessionActions.submit')}
        />
      </Flex>
    </Overlay>
  )
}

export default Feedback
