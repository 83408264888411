import React, { useState, useRef } from 'react'
import { Box } from 'rebass'

import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'

import config from './config'
import Home from './Home'
import { DigitalHuman, i18n } from 'uneeq-react-ui'
import en from './translations/en.json'

import backgroundImage from './assets/img/background-outside-branch.png'
import { testState } from 'uneeq-react-core'
import SessionTimedOut from './SessionTimedOut'

import clickableQuestionsImg from './assets/img/clickableQuestions.png'
import clickableQuestionsMP4 from './assets/video/clickableQuestions.mp4'
import clickableQuestions from './assets/video/clickableQuestions.webm'
import holdSpacebarImg from './assets/img/holdSpacebar.png'
import holdSpacebarMP4 from './assets/video/holdSpacebar.mp4'
import holdSpacebar from './assets/video/holdSpacebar.webm'
import assets from './assets'

i18n.addResourceBundle('en', 'translation', en, true, true)

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  minHeight: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#000000',
  backgroundImage: [
    'none',
    'none',
    'none',
    'none',
    `url('${backgroundImage}')`,
    `url('${backgroundImage}')`
  ],
  overflow: 'hidden',
  position: 'absolute'
}

const loadingTips = [
  {
    title:
      'Questions on the bottom of the screen can be clicked and let you respond to Maya fast',
    videoWebm: clickableQuestions,
    videoMP4: clickableQuestionsMP4,
    img: clickableQuestionsImg,
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Hold down your spacebar to speak to me',
    videoWebm: holdSpacebar,
    videoMP4: holdSpacebarMP4,
    img: holdSpacebarImg,
    showOnDesktop: true,
    showOnMobile: false
  }
]

const App = () => {
  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')
  const [surveyData, setSurveyData] = useState({
    spokenBefore: false,
    techSavvy: false,
    thoughtsOnAI: ''
  })

  const postInit = (uneeq: any) => {
    uneeq.sendTranscript(JSON.stringify(surveyData))
  }

  const tokenRef = useRef<string>()

  const start = (token: string) => {
    tokenRef.current = token
    goTo('digitalHuman')
  }

  const restart = () => goTo('welcome')

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' ? (
          <DigitalHuman
            assets={assets}
            postInit={postInit}
            token={tokenRef.current}
            config={config}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
            loadingTips={loadingTips}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home startSession={start} saveFormData={setSurveyData} />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
