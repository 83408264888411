import uneeqLogo from './assets/img/UneeQ-logo.png'
import config from './config'

const logos = {
  logo1: uneeqLogo,
  logo2: uneeqLogo
}

export const downloadPdf = async (
  type: 'transcript' | 'savedItems',
  items: any,
  sessionId: string
) => {
  import('uneeq-download-pdf').then(
    ({ downloadSavedItemsPdf, downloadTranscriptPdf }) => {
      if (type === 'transcript') {
        downloadTranscriptPdf({
          avatarName: config.avatarName,
          filename: 'josie-transcript',
          content: items,
          sessionId,
          ...logos
        })
      } else {
        downloadSavedItemsPdf({
          filename: 'josie-information',
          content: items,
          sessionId,
          ...logos
        })
      }
    }
  )
}

export default downloadPdf
