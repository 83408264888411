import { useWindowWidth } from '@react-hook/window-size'
import { Checkbox, Label, Textarea } from '@rebass/forms'
import React, { useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { trackHandler } from 'uneeq-react-core'
import { PasscodeOverlay } from 'uneeq-react-ui'
import landingPageVideoFrame from '../assets/video/LandingPageVideo.jpg'
import landingPageVideoPortraitFrame from '../assets/video/LandingPageVideoPortrait.jpg'
import { ReactComponent as TogglePrivacyIcon } from '../assets/img/toggle_privacy_icon.svg'
import { ReactComponent as TogglePrivacyIconOpen } from '../assets/img/toggle_privacy_icon_open.svg'
// import introVideoMP4 from '../assets/video/intro.mp4'
import landingPageVideoMP4 from '../assets/video/LandingPageVideo.mp4'
import landingPageVideoPortraitMP4 from '../assets/video/LandingPageVideoPortrait.mp4'
import config from '../config'
import styles from './styles'

const Paragraphs = () => (
  <>
    <Text as="p">
      We take your privacy seriously, we only use video and audio data so that a
      digital human can understand what you are saying to it. We then share
      audio data with third party service partners who help us with
      transcription. Neither they or we store any audio data once we have
      completed the transcription. For more information read our{' '}
      <Text
        as="a"
        href="/privacy_policy.html"
        target="_blank"
        rel="noreferrer noopener"
        sx={styles.privacyPolicyLink}
      >
        privacy policy
      </Text>
      .
    </Text>
  </>
)

const SophieVideo = () => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={
        smallScreen ? landingPageVideoPortraitFrame : landingPageVideoFrame
      }
    >
      <source
        src={smallScreen ? landingPageVideoPortraitMP4 : landingPageVideoMP4}
        type="video/mp4"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
  saveFormData: (surveyData: any) => void
}

const Home: React.FC<HomeProps> = ({ startSession, saveFormData }) => {
  const [showPrivacySummary, setShowPrivacySummary] = useState(false)
  const [showPasscode, setShowPasscode] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)
  const [spokeToJosie, setSpokeToJosie] = useState(false)
  const [useVideoRegularly, setUseVideoRegularly] = useState(false)
  const [aiImpression, setAIImpression] = useState('')

  const enteredInfo = aiImpression || useVideoRegularly || spokeToJosie

  const startSessionAndSaveData = (token: string) => {
    saveFormData({
      spokenBefore: spokeToJosie,
      techSavvy: useVideoRegularly,
      thoughtsOnAI: aiImpression
    })
    startSession(token)
  }

  const togglePrivacySummary = () => {
    setShowPrivacySummary(!showPrivacySummary)
    setShowSurvey(false)
  }
  const toggleSurvey = () => {
    setShowSurvey(!showSurvey)
    setShowPrivacySummary(false)
  }

  // if (showPasscode && !isBrowserSupported) {
  //   return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  // }

  const StartButton = ({ sx }: any) => (
    <Button
      variant="primary"
      onClick={() => {
        setShowPasscode(true)
      }}
      sx={{ ...styles.letsChatButton, ...sx }}
    >
      {enteredInfo ? 'Submit and start chatting' : `Let's chat`}
    </Button>
  )

  return (
    <Flex sx={styles.mainContainer}>
      <Text sx={styles.mobileImSophie}>
        Meet {config?.avatarName}, our new digital assistant
      </Text>
      <SophieVideo />

      <Box sx={styles.textContainer}>
        <Box sx={styles.titleContainer}>
          <Text sx={styles.imSophieText}>Meet {config?.avatarName}</Text>
        </Box>
        {/*<Box sx={styles.videoContainer}>*/}
        {/*  <video*/}
        {/*    autoPlay={false}*/}
        {/*    loop={true}*/}
        {/*    playsInline={true}*/}
        {/*    controls={true}*/}
        {/*    style={{ width: '388px', objectFit: 'cover', marginLeft: '5px' }}*/}
        {/*  >*/}
        {/*    <source src={introVideoMP4} type="video/mp4" />*/}
        {/*    Loading...*/}
        {/*  </video>*/}
        {/*</Box>*/}
        <Text sx={styles.subtitle}>
          I’m a digital assistant that can help you with your health.
        </Text>
        <StartButton sx={styles.startButton} />
        <Text sx={styles.surveyToggle} onClick={toggleSurvey}>
          Fill out your details to help us improve this experience
          <Box sx={styles.togglePrivacyIcon}>
            {showSurvey ? <TogglePrivacyIconOpen /> : <TogglePrivacyIcon />}
          </Box>
        </Text>

        <Box
          sx={{
            ...styles.surveyContainer,
            maxHeight: showSurvey ? '600px' : '1px',
            opacity: showSurvey ? 1 : 0
          }}
        >
          <Label htmlFor="spokeToJosie">
            <Checkbox
              sx={styles.checkbox}
              name="spokeToJosie"
              id="spokeToJosie"
              checked={spokeToJosie}
              onChange={() => setSpokeToJosie(!spokeToJosie)}
            />
            I have spoken to an AI before (chatbot)
          </Label>
          <Label htmlFor="useVideoRegularly">
            <Checkbox
              sx={styles.checkbox}
              name="useVideoRegularly"
              id="useVideoRegularly"
              checked={useVideoRegularly}
              onChange={() => setUseVideoRegularly(!useVideoRegularly)}
            />
            I consider myself tech savvy
          </Label>
          <Label htmlFor="aiImpression" mt={2}>
            What do you think of AI?
          </Label>
          <Textarea
            id="aiImpression"
            name="aiImpression"
            sx={styles.textarea}
            my={2}
            value={aiImpression}
            onChange={e => setAIImpression(e.target.value)}
          />
        </Box>

        <Text sx={styles.privacyTextToggle} onClick={togglePrivacySummary}>
          By using this service you agree that we can collect some data about
          your conversation
          <Box sx={styles.togglePrivacyIcon}>
            {showPrivacySummary ? (
              <TogglePrivacyIconOpen />
            ) : (
              <TogglePrivacyIcon />
            )}
          </Box>
        </Text>

        <Flex
          sx={{
            ...styles.paragraphContainer,
            maxHeight: showPrivacySummary ? '1000px' : '1px'
          }}
        >
          <Paragraphs />
        </Flex>

        <StartButton
          sx={{ display: ['none', 'none', 'none', 'none', 'block', 'block'] }}
        />

        {/*<Text sx={styles.disclaimer}>*/}
        {/*  Interests in the ASB KiwiSaver Scheme (Scheme) are issued by ASB Group*/}
        {/*  Investments Limited, a wholly owned subsidiary of ASB Bank Limited*/}
        {/*  (ASB). ASB provides Scheme administration and distribution services.*/}
        {/*  No person guarantees interests in the Scheme. Interests in the Scheme*/}
        {/*  are not deposits or other liabilities of ASB. They are subject to*/}
        {/*  investment risk, including possible loss of income and principal*/}
        {/*  invested. For more information see the{' '}*/}
        {/*  <a*/}
        {/*    href="https://www.asb.co.nz/documents/kiwisaver/forms/product-disclosure-statement.html"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    ASB KiwiSaver Scheme Product Disclosure Statement*/}
        {/*  </a>{' '}*/}
        {/*  available from ASB’s website and the register of offers of financial*/}
        {/*  products at{' '}*/}
        {/*  <a*/}
        {/*    href="https://www.disclose-register.companiesoffice.govt.nz"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    www.disclose-register.companiesoffice.govt.nz*/}
        {/*  </a>{' '}*/}
        {/*  (search for ASB KiwiSaver Scheme).*/}
        {/*</Text>*/}
        <Text sx={styles.recaptchaDisclaimer}>
          reCAPTCHA v3 has been implemented to fight spam and abuse on this site
          and it's use is subject to the Google Privacy Policy and Terms of Use.
        </Text>
      </Box>
      {showPasscode && (
        <PasscodeOverlay
          start={trackHandler(startSessionAndSaveData, 'lets-chat-btn')}
          close={() => setShowPasscode(!showPasscode)}
          config={config}
        />
      )}
    </Flex>
  )
}

export default Home
