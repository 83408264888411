import React, { useState } from 'react'
import { Flex, Box } from 'rebass'
import { Label, Checkbox, Textarea } from '@rebass/forms'

import styles from './styles'

const MultiSelect = ({ options, selected, setter }: any) => {
  const [showInput, setShowInput] = useState<any>(false)
  const [otherField, setOtherField] = useState<any>('')

  const toggleCheckbox = (value: boolean, response: any) => {
    const isInput = options.find((rsp: any) => {
      return rsp.id === response.id && rsp.input
    })

    if (isInput) setShowInput(!showInput)

    if (options.some((resp: any) => resp.exclusive)) {
      if (response.exclusive) {
        // set all others to false, except the exclusive one we selected
        setShowInput(false)
        setter({
          ...Object.fromEntries(Object.keys(selected).map(key => [key, false])),
          [response.id]: value
        })
      } else {
        // toggle selected, and also unselect exclusive
        setter({
          ...Object.fromEntries(
            Object.keys(selected).map(key => {
              const isExclusive = options.find((rsp: any) => {
                return rsp.id == key && rsp.exclusive
              })

              return [key, isExclusive ? false : selected[key]]
            })
          ),
          [response.id]: value
        })
      }
    } else {
      setter({
        ...selected,
        [response.id]: value
      })
    }
  }

  if (!options) return null

  return (
    <Box sx={styles.select}>
      <Flex sx={styles.multiSelect}>
        {options &&
          options.map((option: any) => (
            <Flex
              key={option.id}
              sx={styles.checkboxOption}
              alignItems="center"
            >
              <Label sx={styles.checkboxLabel}>
                <Checkbox
                  id={option.id}
                  name={option.id}
                  checked={!!selected[option.id]}
                  onChange={() => toggleCheckbox(!selected[option.id], option)}
                />
                {option.label}
              </Label>
            </Flex>
          ))}
      </Flex>
      {showInput && (
        <Textarea
          sx={styles.textArea}
          id="comment"
          data-testid="comment-textarea"
          name="comment"
          value={otherField}
          onChange={e => setOtherField(e.target.value)}
        />
      )}
    </Box>
  )
}

export default MultiSelect
